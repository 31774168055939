define(['siteObj', '$console'], function(siteObj, $console) {
  var mapCanvas = function () {
    var self = this;

    const _selectors = {
      map: 'map-canvas'
    }

    const _init = function () {
      let callbackName = self.JSONP(self.executeMapBinding);
      self.loadMapsAPI(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDAe9q-8nn_s6GdxTOsV1mI_JYpQR7wKuw&callback=${callbackName}`);
    };

    const _loadMapsAPI = (url) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.setAttribute('async', '');
      script.setAttribute('defer', '');
      script.src = url;
      document.body.appendChild(script);
    };

    const _JSONP = (callback) => {
      let timestamp = Date.now();
      let generatedFunctionName = 'jsonp' + Math.round(timestamp + Math.random() * 1000001);

      window[generatedFunctionName] = () => {
        callback();
        window[generatedFunctionName] = undefined;
      };

      setTimeout(() => {
        if (window[generatedFunctionName]) {
          window[generatedFunctionName] = undefined;
          $console.error('sorry something went wrong with that function');
        }
      }, 5000);

      return generatedFunctionName;
    };

    const _executeMapBinding = function () {
      var geocoder = new google.maps.Geocoder();

      let contentString = '<div class="info-window">' +
        '<h2>Find Us</h2>' +
        '<div class="salon-address"> ' +
        siteObj.maps.address +
        '</div>' +
        '<div class="info-window-description"> ' +
        siteObj.maps.description +
        '</div>' +
        '</div>';

      geocoder.geocode({
        'address': siteObj.maps.postcode
      }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {

          let location = results[0].geometry.location;
          let lat = location.lat();
          let lng = location.lng();
          let latlng = new google.maps.LatLng((lat + 0.005), lng);
          let mapOptions = {
            zoom: 15,
            center: location,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          };

          let map = new google.maps.Map(document.getElementById(_selectors.map), mapOptions);

          map.panTo(latlng);

          let marker = new google.maps.Marker({
            position: location,
            map: map,
            title: siteObj.maps.postcode
          });

          let infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          infowindow.open(map, marker);

          google.maps.event.addListener(marker, 'click', function () {
            infowindow.open(map, marker);
          });

        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    };

    self.init = _init;
    self.executeMapBinding = _executeMapBinding;
    self.loadMapsAPI = _loadMapsAPI;
    self.JSONP = _JSONP;
  };

  return mapCanvas;
});
